<template>
  <div>
    <div class="container mt-5 mb-3">
      <div class="row">
        <div class="col-lg-4" v-for="(item, id) in getmovies" :key="id">
          <div
            class="card p-3 mb-2 h-100">
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-row align-items-center">
                <div class="ms-2 c-details">
                  <h6 class="mb-0">{{ item.id + 1 }}</h6>
                </div>
              </div>
            </div>
            <div class="mt-5">
              <h2 class="heading">{{ item.titolo }}</h2>
              <div class="mt-5">
                <video width="320" height="240" controls lang="it" preload="none">
                  <source :src="item.path" type="video/mp4" />
                </video>
                  <p>{{item.path}}</p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { getMovies } from "./api";

import { mapActions, mapGetters } from "vuex";
export default {
  name: "HelloWorld",
  data() {
    return {
      movie: null,
    };
  },
  computed: {
    ...mapGetters(["getmovies"]),
  },
  methods: {
    ...mapActions(["movielist"]),
    load() {
      this.movielist(this.movie);
    },
async firstRequest() {
  try {
    this.movie = await getMovies();
    this.load();
  } catch (error) {
    // Gestisci l'errore qui, ad esempio log o notifiche
    console.error(error);
  }
}

  },
  mounted() {
    this.firstRequest();
  },
};
</script>

<style>
h2 {
  font-size: 30px !important;
  text-align: left;
  text-transform: capitalize;
  font-weight: 600;
}
video {
  width: 100%;
  max-height: 100%;
}
.card {
  background-color: #f8f9fa;
  border: 1px solid #ced4da;
  border-radius: 8px;
  padding: 20px;
}

.card h6 {
  font-size: 16px;
  color: #6c757d;
  margin-bottom: 10px;
}

.card h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.card video {
  width: 100%;
  height: auto;
}

.card .heading {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
}

.card .mt-5 {
  margin-top: 20px;
}

.card .mb-2 {
  margin-bottom: 10px;
}

.card .d-flex {
  display: flex;
}

.card .justify-content-between {
  justify-content: space-between;
}

.card .flex-row {
  flex-direction: row;
}

.card .align-items-center {
  align-items: center;
}

.card .ms-2 {
  margin-left: 10px;
}

.card .c-details {
  color: #6c757d;
}

</style>