const state = {
    myMovie: []
};
const getters = {
    getmovies: state => { return state.myMovie },

};
const mutations = {
    setmovie: (state, att) => { state.myMovie = att },
};
const actions = {
    movielist: (context, movie) => { context.commit('setmovie', movie) },
};

export default {
    state,
    getters,
    mutations,
    actions
}