import axios from "axios";

export async function getMovies() {
    try {
        const response = await axios.get("https://giustinoromano.ddns.net:8089/paths");
        return response.data;
    } catch (error) {
        // Gestisci l'errore qui, ad esempio log o notifiche
        throw new Error("Errore durante la richiesta dei film.");
    }
}