<template>
  <div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12">
          <h1>Justinflix</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderTop",
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
h1 {
  font-family: "Bebas Neue", cursive;
  font-size: 200px;
  color: #e50914;
}

@media (max-width: 767px) {
  h1 {
    font-family: "Bebas Neue", cursive;
    font-size: 100px;
    color: #e50914;
  }
}
</style>
