import { createRouter, createMemoryHistory } from 'vue-router' //createWebHashHistory()
import HomeView from '../views/HomeView.vue'

const routes = [{
    path: '/',
    name: 'home',
    component: HomeView
}, ]

const router = createRouter({
    history: createMemoryHistory(), //createWebHashHistory()
    routes
})

export default router